module.exports = {
  // Remember to also add these to the styleguide utilities page (see styleguide_utilities.jsx)
  cursor: {
    help: 'help',
    move: 'move',
  },
  textColor: {
    muted: '#707070',
    disabled: '#8a8a8a',
    default: '#161E2E', // gray-900
    link: '#1c64f2', // blue-600
    info: '#1c64f2', // blue-600
    success: '#057a4a', // green-600
    danger: '#e02424', // red-600
    highlight: '#6c2bd9', // purple-700
    warning: '#ff5a1f', // orange-500
  },
  colors: {
    inherit: 'inherit',
    current: 'currentColor',
    green: {
      // Tailwind UI colors but tweaked to be a bit more green (-6deg)
      50: '#f4faf7',
      100: '#def7ea',
      200: '#bcf0d5',
      300: '#84e1b3',
      400: '#31c47f',
      500: '#0ea060',
      600: '#057a4a',
      700: '#046c44',
      800: '#035436',
      900: '#01462f',
    },
  },
  boxShadow: (theme) => ({
    highlight: `0 0 0 3px rgba(118, 169, 250, 0.45), 0 0 0 1px ${theme('colors.blue.500')}`,
    popover: '0px 1px 1px 0px rgba(0, 0, 0, 0.12), 0px 2px 5px 0px rgba(54, 65, 82, 0.08)',
  }),
  typography: (theme) => ({
    DEFAULT: {
      css: {
        color: theme('colors.gray.900'),
        a: {
          color: theme('colors.blue.500'),
          fontWeight: 'normal',
          '&:hover': {
            color: theme('colors.blue.500'),
            textDecoration: 'underline',
          },
        },
      },
    },
  }),
};
